import axios from 'axios'
import { Toast } from 'vant'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000 // 超时配置
})

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = localStorage.getItem('token') || ''
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    const { errorCode, data, errorMessage } = response.data
    if (errorCode === 'NO-ERROR') {
      return data
    } else {
      Toast(errorMessage)
      return Promise.reject(response.data)
    }
  },
  function (error) {
    Toast(error?.response?.data?.errorMessage || '服务端异常')
    return Promise.reject(error)
  }
)

export const http = (config) => {
  return new Promise((resolve, reject) => {
    instance
      .request(config)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const request = {
  get(url, params = {}, config) {
    return http({ url, params, ...config, method: 'GET' })
  },
  post(url, data = {}, config) {
    return http({ url, data, ...config, method: 'POST' })
  },
  put(url, data = {}, config) {
    return http({ url, data, ...config, method: 'PUT' })
  },
  delete(url, data = {}, config) {
    return http({ url, data, ...config, method: 'DELETE' })
  },
  // 上传文件，指定 'Content-Type': 'multipart/form-data'
  upload(url, data = {}, config) {
    return http({
      url,
      data,
      ...config,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }
}

export default request
