import request from '@/utils/request'

export default {
  // 个人信息详情
  getUserDetail(data) {
    return request.get('admin/upms/sysUser/view', data)
  },
  // 获取人员列表
  getUserList(data) {
    return request.post('admin/upms/sysUser/list', data)
  },
}
