import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/tabbar/home.vue'
import User from '../views/tabbar/user.vue'
import Assign from '../views/tabbar/assign.vue'
import { getUrlParams } from '@/utils/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/user',
    name: 'user',
    component: User
  },
  {
    path: '/assign',
    name: 'assign',
    component: Assign
  },
  {
    path: '/inventory-list',
    name: 'inventory',
    meta: {
      title: '库存查询'
    },
    component: () => import('../views/warehouse/inventory/list.vue')
  },
  {
    path: '/warehouseIn-list',
    name: 'warehouseIn',
    meta: {
      title: '入库'
    },
    component: () => import('../views/warehouse/warehouseIn/list.vue')
  },
  {
    path: '/warehouseIn-add',
    name: 'warehouseInAdd',
    meta: {
      title: '新增入库'
    },
    component: () => import('../views/warehouse/warehouseIn/add.vue')
  },
  {
    path: '/warehouseIn-detail',
    name: 'warehouseInDetail',
    meta: {
      title: '入库详情'
    },
    component: () => import('../views/warehouse/warehouseIn/detail.vue')
  },
  {
    path: '/warehouseOut-list',
    name: 'warehouseOut',
    meta: {
      title: '出库'
    },
    component: () => import('../views/warehouse/warehouseOut/list.vue')
  },
  {
    path: '/warehouseOut-add',
    name: 'warehouseOutAdd',
    meta: {
      title: '新增出库'
    },
    component: () => import('../views/warehouse/warehouseOut/add.vue')
  },
  {
    path: '/warehouseOut-detail',
    name: 'warehouseOutDetail',
    meta: {
      title: '出库详情'
    },
    component: () => import('../views/warehouse/warehouseOut/detail.vue')
  },
  {
    path: '/transfer-list',
    name: 'transfer',
    meta: {
      title: '调拨'
    },
    component: () => import('../views/warehouse/transfer/list.vue')
  },
  {
    path: '/transfer-add',
    name: 'transferAdd',
    meta: {
      title: '新增调拨'
    },
    component: () => import('../views/warehouse/transfer/add.vue')
  },
  {
    path: '/transfer-detail',
    name: 'transferDetail',
    meta: {
      title: '调拨详情'
    },
    component: () => import('../views/warehouse/transfer/detail.vue')
  },
  {
    path: '/messages-list',
    name: 'messages',
    meta: {
      title: '待办提醒'
    },
    component: () => import('../views/messages/messages/list.vue')
  },
  {
    path: '/system-list',
    name: 'system',
    meta: {
      title: '系统通知'
    },
    component: () => import('../views/messages/system/list.vue')
  },
  {
    path: '/knowledge-list',
    name: 'knowledge',
    meta: {
      title: '知识推送'
    },
    component: () => import('../views/messages/knowledge/list.vue')
  },
  {
    path: '/equipment-list',
    name: 'equipment',
    meta: {
      title: '我的设备'
    },
    component: () => import('../views/equipment/equipment/list.vue')
  },
  {
    path: '/equipment-detail',
    name: 'equipment',
    meta: {
      title: '设备详情'
    },
    component: () => import('../views/equipment/equipment/detail.vue')
  },
  {
    path: '/inspection-list',
    name: 'inspection',
    meta: {
      title: '点巡检'
    },
    component: () => import('../views/equipment/inspection/list.vue')
  },
  {
    path: '/inspection-add',
    name: 'inspectionAdd',
    meta: {
      title: '新建点检'
    },
    component: () => import('../views/equipment/inspection/add.vue')
  },
  {
    path: '/inspection-detail',
    name: 'inspectionDetail',
    meta: {
      title: '点检详情'
    },
    component: () => import('../views/equipment/inspection/detail.vue')
  },
  {
    path: '/maintain-list',
    name: 'maintain',
    meta: {
      title: '保养'
    },
    component: () => import('../views/equipment/maintain/list.vue')
  },
  {
    path: '/maintain-add',
    name: 'maintainAdd',
    meta: {
      title: '新建保养'
    },
    component: () => import('../views/equipment/maintain/add.vue')
  },
  {
    path: '/maintain-detail',
    name: 'maintainDetail',
    meta: {
      title: '保养详情'
    },
    component: () => import('../views/equipment/maintain/detail.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * 路由跳转的时候判断token是否存在
 */
router.beforeResolve(async (to, from, next) => {
  const params = getUrlParams()
  if (params.token) {
    localStorage.setItem('token', `Bearer ${params.token}`)
    localStorage.setItem('userId', params.userId)
    next()
  } else {
    next()
  }
})

export default router
