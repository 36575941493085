import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
import 'vant/lib/index.css'
import './style/base.scss'
import './style/common.scss'
import {
  Form,
  Field,
  Button,
  NavBar,
  DropdownMenu,
  DropdownItem,
  Tab,
  Tabs,
  Card,
  Tabbar,
  TabbarItem,
  Dialog,
  Search,
  Icon,
  List,
  Cell,
  CellGroup,
  Popup,
  Picker,
  Stepper,
  DatetimePicker,
  Uploader
} from 'vant'

Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(NavBar)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Card)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Dialog)
Vue.use(Search)
Vue.use(Icon)
Vue.use(List)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Stepper)
Vue.use(DatetimePicker)
Vue.use(Uploader)

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
