<template>
  <div class="page-user page-common">
    <van-nav-bar title="个人中心" border />
    <div class="common-main">
      <div class="top">
        <div class="avatar">
          <img style="width: 50px" :src="user.headImageUrl" mode="widthFix" />
        </div>
        <div class="info">
          <div>
            <div class="name">{{user.showName}} ｜ {{user.loginName}} </div>
            <div class="company"> {{user.deptIdDictMap?.name}}</div>
            <div class="labels">
              <div class="ellipsis" style="max-width: 200px">{{user.userTypeDictMap?.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="list-item">
          <div>
            <img src="@/assets/cache.png" alt="" />
            <span>缓存</span>
          </div>
          <div @click="clear">
            <span style="color: #607fff">清除缓存</span>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar v-model="active" route :fixed="false">
      <van-tabbar-item icon="comment" replace to="assign">信息</van-tabbar-item>
      <van-tabbar-item icon="wap-home-o" replace to="/">仓库</van-tabbar-item>
      <van-tabbar-item icon="user-o" replace to="user">个人</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import api from '@/api/user/user'

export default {
  data() {
    return {
      active: 1,
      userId: undefined,
      user: {},
    }
  },
  mounted() {
    this.userId = localStorage.getItem('userId')
    this.getUserDetail(this.userId)
  },
  methods: {
    clear() {},
    async getUserDetail(userId) {
      const res = await api.getUserDetail({ userId })
      localStorage.setItem('userInfo', JSON.stringify(res))
      this.user = res
    }
  }
}
</script>
<style lang="scss" scoped>
.page-user {
  color: #364767;
  font-size: 14px;

  .common-main {
    padding: 10px;
  }

  .top {
    padding: 30px;
    display: flex;
    border-radius: 4px;
    background-color: #f7f9fc;

    .info {
      margin-left: 20px;

      .name {
        font-size: 16px;
        font-weight: bold;
      }

      .company {
        margin-top: 7px;
        font-size: 12px;
        color: #8a8ca5;
      }

      .labels {
        display: flex;
        margin-top: 7px;
        overflow-y: auto;

        div {
          min-width: 100px;
          font-size: 14px;
          border-radius: 8px;
        }
      }
    }
  }

  .list {
    margin-top: 10px;
    .list-item {
      display: flex;
      padding: 15px 0 15px 10px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #f0f2f5;

      span {
        vertical-align: middle;
        margin: 0 10px;
      }

      img {
        width: 24px;
        vertical-align: middle;
      }
    }
  }

  .btn {
    padding: 0px 12%;
    margin: 50px auto 0;
    width: 70%;
    border-radius: 8px;
    color: #347caf;
    border: 1px solid #347caf;
    background: #fff;
    line-height: 80px;
  }

  .dialogshow {
    padding: 15px;
    text-align: center;
  }
}
</style>
