<template>
  <div class="page-home page-common">
    <van-nav-bar title="仓库与备件" border />
    <div class="common-main">
      <div class="tabs" :title="items.name" v-for="(items, index) in tabList" :key="index">
        <div class="titleName">{{ items.name }}</div>
        <div class="tab">
          <div class="enetrName" v-for="(item, index2) in items.enterList" :key="index2">
            <img :src="item.img" alt="" @click="jumpTask(item.url)" />
            <div class="cardName">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar v-model="active" route :fixed="false">
      <van-tabbar-item icon="comment" replace to="assign">信息</van-tabbar-item>
      <van-tabbar-item icon="wap-home-o" replace to="/">仓库</van-tabbar-item>
      <van-tabbar-item icon="user-o" replace to="user">个人</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import router from '@/router'

export default {
  data() {
    return {
      active: 1,
      tabList: [
        {
          name: '仓库',
          enterList: [
            {
              img: require('@/assets/home/warehouse-scan.png'),
              name: '库存查询',
              url: '/inventory-list'
            },
            {
              img: require('@/assets/home/warehousing.png'),
              name: '入库',
              url: '/warehouseIn-list'
            },
            {
              img: require('@/assets/home/delivery.png'),
              name: '出库',
              url: '/warehouseOut-list',
            },
            {
              img: require('@/assets/home/allocation.png'),
              name: '调拨',
              url: '/transfer-list',
            }
          ]
        }
      ]
    }
  },
  methods: {
    onClickLeft() {},
    jumpTask(url) {
      router.push(url)
    }
  }
}
</script>
<style lang="scss" scoped>
.page-home {
  .tabs {
    padding: 10px 10px 20px;

    .titleName {
      font-size: 16px;
      font-weight: normal;
      color: #6c6c6c;
      text-align: left;
    }
    .tab {
      display: flex;
      flex-wrap: wrap;
      font-size: 16px;
      font-weight: normal;
      color: #000;
      text-align: left;
    }

    .enetrName {
      width: 25%;
      text-align: center;
    }

    .titleName {
      color: #8a8ca5;
      margin-top: 10px;
    }

    .cardName {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #3e5172;
      margin-top: 10px;
    }

    img {
      width: 45px;
      margin-top: 30px;
    }
  }
}
</style>
