import { Html5Qrcode } from 'html5-qrcode'
import { Toast } from 'vant'

export function getUrlParams(url) {
  url = url || location.href
  const queryString = url.split('?')[1]
  if (!queryString) {
    return {}
  }
  const pairs = queryString.split('&')
  const params = {}
  for (const pair of pairs) {
    const [key, value] = pair.split('=')
    params[key] = decodeURIComponent(value)
  }
  return params
}

export function scanCode() {
  const scanDom = document.createElement('div')
  scanDom.className = 'qrcode'
  const readerDom = document.createElement('div')
  readerDom.id = 'reader'
  scanDom.appendChild(readerDom)
  scanDom.style.opacity = 'none'
  document.body.appendChild(scanDom)
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      Html5Qrcode.getCameras()
        .then((devices) => {
          if (devices && devices.length) {
            scanDom.style.display = 'flex'
            const html5QrCode = new Html5Qrcode('reader')
            html5QrCode
              .start(
                { facingMode: 'environment' },
                {
                  fps: 2, // 可选，每秒帧扫描二维码
                  qrbox: { width: 250, height: 250 } // 可选，如果你想要有界框UI
                },
                (decodedText, decodedResult) => {
                  resolve({
                    decodedText,
                    decodedResult
                  })
                  scanDom.remove()
                  html5QrCode.stop()
                }
              )
              .catch((err) => {
                Toast('扫码失败')
                scanDom.remove()
                html5QrCode.stop()
                reject()
                console.log('扫码错误信息', err)
              })
          }
        })
        .catch((err) => {
          console.log('请打开相机权限', err)
          Toast('请打开相机权限')
        })
    }, 50)
  })
}
