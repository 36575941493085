<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
import api from '@/api/user/user'

export default {

  async mounted() {
    const userId = localStorage.getItem('userId')
    if (userId) {
      const res = await api.getUserDetail({ userId })
      localStorage.setItem('userInfo', JSON.stringify(res))
    }
    
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9999;

  #reader {
    width: 100%;
  }
}

</style>
